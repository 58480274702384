import { Suspense } from 'react';
import Loader from '../commons/Loader';
import logo from '../../xassets/pepspot_wordmark.svg';
import { Link } from '@reach/router';

const Navbar = () => {
	// get user dta via props. shift link according to what user data is
	return (
		<header className='nav_bar'>
			{/* <img src={logo} className='app_logo' alt='logo' /> */}
			<h1 className='app_title_logo'>
				<Link to='/home'>
					<Suspense
						delay={`50ms`}
						fallback={
							<div style={{ height: '100vh', width: '100%' }}>
								<div className='route_container'>
									<Loader size={20} />
								</div>
							</div>
						}>
						<img
							src={logo}
							className='app_logo'
							alt='logo'
							width='112px'
							height='48px'
						/>
					</Suspense>
				</Link>
			</h1>
		</header>
	);
};

export default Navbar;
