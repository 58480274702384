import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookF,
	faTwitter,
	faInstagram,
	faYoutube,
	faSnapchat,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

function SocialLister(props) {
	//#region Hooks and Variables
	const { social_list } = props;
	//#endregion
	return (
		<ul className='foot_comp_social_list'>
			{social_list &&
				social_list.map((soc) => (
					<a
						key={soc.id}
						href={soc.link}
						className={`social_link ${soc.theme}`}>
						<FontAwesomeIcon
							icon={soc.pic}
							aria-label={soc.name}
							className='social_icon'
							style={{ width: '28px', height: '28px', margin: '5px' }}
						/>
					</a>
				))}
		</ul>
	);
}

SocialLister.propTypes = {
	social_list: PropTypes.array,
};

SocialLister.defaultProps = {
	social_list: [
		{
			id: 1,
			name: 'facebook',
			link: 'https://www.facebook.com/thepepspot',
			pic: faFacebookF,
			theme: 'fb',
		},
		{
			id: 2,
			name: 'instagram',
			link: 'https://www.instagram.com/pepspot_',
			pic: faInstagram,
			theme: 'insta',
		},
		{
			id: 3,
			name: 'twitter',
			link: 'https://twitter.com/Pepspot_',
			pic: faTwitter,
			theme: 'twit',
		},
		{
			id: 4,
			name: 'youtube',
			link: 'https://www.youtube.com/channel/UC90ViyDwZUlSy6Kn9JVTAKA',
			pic: faYoutube,
			theme: 'yt',
		},
		{
			id: 5,
			name: 'snapchat',
			link: 'https://www.snapchat.com/add/thepepspot',
			pic: faSnapchat,
			theme: 'snap',
		},
		{
			id: 6,
			name: 'linkedin',
			link: 'https://www.linkedin.com/company/14059355/',
			pic: faLinkedin,
			theme: 'in',
		},
	],
};

export default SocialLister;
