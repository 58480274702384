import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import SocialLister from '../commons/SocialLister';

// import Img from '../common/Img';
//#endregion

//#region Project Components
//#endregion

//#region Styles and Image Resources
//#endregion

//#region Local Components => to be refactored
// import React from 'react'
// import PropTypes from 'prop-types'

function FooterLister(props) {
	//#region Hooks and Variables
	const { head, list, className, children } = props;
	//#endregion
	return (
		<section className={className}>
			<h4 className='foot_title'>{head}</h4>
			<ul className='foot_links_list'>
				{list &&
					list.map(({ name, to }, i) => (
						<li className='foot_links_list_item' key={i}>
							<Link to={to}>{name}</Link>
						</li>
					))}
			</ul>
			{children && children}
		</section>
	);
}

FooterLister.propTypes = {
	head: PropTypes.string.isRequired,
	list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

// export default FooterLister

//#endregion

const Footer = (props) => {
	//#region Hooks and Variables
	// const { filters, about, extras } = props;
	//#endregion

	return (
		<footer className='footer_container'>
			<section className='foot_comp_details'>
				<h4 className='comp_name'>PEPSPOT ™</h4>
				<h5 className='comp_legal_name'>Dflash Tech Private Limited</h5>
				<p className='comp_desc'>
					Find venues for gatherings along with all the party pre-requisites at
					one platform.
				</p>
				<div className='comp_social'>
					<a
						className='app_link'
						href='https://www.thepepspot.com'
						target='_blank'
						rel='noopener noreferrer'>
						Go to Pepspot
					</a>
					<h5 className='foot_comp_social_title'>We are social.</h5>
					<SocialLister />
				</div>
			</section>
			{/* <div className='foot_lists'>
				<FooterLister
					className='foot_quick_links'
					head='Cities'
					list={filters}
				/>
				<FooterLister className='foot_about' head='About' list={about} />
				<FooterLister className='foot_extras' head='Extras' list={extras} />
			</div> */}
		</footer>
	);
};

Footer.propTypes = {
	// filters: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Footer;
