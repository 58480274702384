import { lazy, Suspense, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { Router, navigate } from '@reach/router';
import './xstyles/app.css';

import app, {
	getAuthRef,
	propertyprovidersCollectionRef,
	usersCollectionRef,
} from './services/fb';

import Loader from './components/commons/Loader';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/error_handling/ErrorFallback';
import Navbar from './components/nav/Navbar';
import Footer from './components/nav/Footer';

import { gotUserAuthenticated } from './store/reducer_auth';
import {
	errorGettingUserData,
	fetchingUserData,
	gotUserData,
	gotUserInitData,
} from './store/reducer_user';

const Landing = lazy(() => import('./pages/Landing'));
const VendorHome = lazy(() => import('./pages/VendorHome'));
const PropertyHome = lazy(() => import('./pages/PropertyHome'));
const NotFound = lazy(() => import('./pages/NotFound'));

export const routes_nav = {
	home: { name: 'Home', path: '/' },
	venues: { name: 'Venues', path: '/venues' },
	venue_details: { name: 'Venue Page', path: '/venues/:vid' },
	login: { name: 'Login', path: '/login' },
	user: { name: 'Profile', path: '/profile' },
	cart: { name: 'Cart', path: '/cart/' },
	contact: { name: 'Contact', path: '/contact' },
	blog: { name: 'Blog', path: '/blog' },
	media: { name: 'Media', path: '/media' },
};

const routes_sys = {
	faq: { name: 'FAQs', path: '/faqs/' },
	about: { name: 'About', path: '/about' },
	privacy: { name: 'Privacy Policy', path: '/privacy_policy' },
	refunds: { name: 'Refund Policy', path: '/refund_policy' },
	tnc: { name: 'Terms & Conditions', path: '/tnc' },
	checkout: { name: 'Checkout', path: '/checkout/' },
	invoices: { name: 'Invoices', path: '/payment_status' },
	invoice: { name: 'Invoices', path: '/payment_status/:tid' },
};

export const prop_owner_routes = {
	landing: {
		name: 'Landing',
		link: '/propertyOwners',
		route: 'propertyOwners',
	},
};

const links_about = [
	{ name: 'Blogs', to: routes_nav.blog.path },
	{ name: 'Contact Us', to: routes_nav.contact.path },
	{ name: 'About Pepspot', to: routes_sys.about.path },
	{ name: 'Memories Gallery', to: routes_nav.media.path },
];

const links_extras = [
	{ name: 'FAQs', to: routes_sys.faq.path },
	{ name: 'Terms & Conditions', to: routes_sys.tnc.path },
	{ name: 'Privacy Policy', to: routes_sys.privacy.path },
	{ name: 'Refund Policy', to: routes_sys.refunds.path },
];

const App = () => {
	const [isSignedIn, setIsSignedIn] = useState(false);

	const dispatch = useDispatch();
	const authUser = useSelector((state) => state.auth.user);
	const activeUser = useSelector((state) => state.user);

	// Listen to the Firebase Auth state and set the local state.
	useEffect(() => {
		const unregisterAuthObserver = app.auth().onAuthStateChanged((user) => {
			setIsSignedIn(!!user);

			if (user?.uid !== null) {
				// console.log(
				// 	'User is:',
				// 	user.uid,
				// 	user.providerData[0]
				// 	// {
				// 	// 	userid: ,
				// 	// 	userdata: user.providerData[0],
				// 	// }
				// );

				batch(() => {
					dispatch(gotUserAuthenticated());
					dispatch(fetchingUserData());
				});
				// Lookup user to see if it exists in the database
				// Match with phone number,
				// if the user exists in the database, fetch the user data and store it in store
				usersCollectionRef
					.where('uid', '==', user.uid)
					.get()
					.then((querySnapshot) => {
						if (querySnapshot.empty) {
							// Check for a case where an error occurs due to loss of network or bad network.
							console.log(
								"[App.js > onAuthStateChanged > getUserData] > User doesn't exist on DB, Create a New User"
							);
							//
							// User has signed in for the first time.
							let newUser = {
								uid: user.uid,
								email: user.email,
								displayName: user.displayName,
								phoneNum: user.phoneNumber,
							};
							console.group(
								'[App.js > onAuthStateChanged]: User Logged in for the first time.'
							);
							// console.group('User Data is: ');
							// console.log('Auth returned: ', user);
							console.log('-> UID: ', newUser.uid);
							console.log('-> Name: ', newUser.displayName);
							console.log('-> Email: ', newUser.email);
							console.log('-> Phone Number: ', newUser.phoneNum);
							console.groupEnd();

							dispatch(
								gotUserInitData({ newUser: newUser, is_new_user: true })
							);

							//Navigate to profile. where fields {email/mobile num} with intial data are disabled to edit by default.
							navigate(`/home`);

							// create user profile.
							// dispatch(createUser(user));
						} else {
							querySnapshot.forEach((doc) => {
								const incomingData = doc.data();
								// console.log(
								// 	'[App.js > onAuthStateChanged > getUserData] > User Exists with id: ',
								// 	doc.id,
								// 	' & has data: ',
								// 	incomingData
								// );
								dispatch(gotUserData({ user: incomingData, docRef: doc.id }));
								// // // User is signed in and user exists.
								// console.group(
								// 	'[App.js > onAuthStateChanged]: New Authentication Detected'
								// );
								// console.log('-> Authenticated user:', user.uid);
								// console.log('-> Existing User: ', activeUser.uid);
								// activeUser.uid !== null &&
								// 	(user.uid === activeUser.uid
								// 		? console.log('-> User already logged in')
								// 		: console.log('-> Logging out the Active User'));
								// console.groupEnd();
							});
						}
					})
					.catch((error) => {
						const simple_error = {
							error_code: error.code,
							error_msg: error.message,
						};
						console.error(
							'[App.js > onAuthStateChanged] > Error checking user ref: ',
							simple_error
						);
						dispatch(errorGettingUserData({ error: simple_error }));
					});
				// dispatch(getUserData(user));
				// if (activeUser.uid !== null) {
				// } else if (activeUser.uid === null) {
				// }
			} else {
				// User is signed out.
				// dispatch action to set active user as null which in turn should change auth states and only fetch cached posts from fbfs
				// else create users entry on db and upon succesful completion add it to store
			}
		});
		return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
	}, []);

	// if (!isSignedIn) {
	// 	return null;
	// }

	// let user = app.auth().currentUser ? app.auth().currentUser : null;
	// console.log(
	// 	'[Login > Authed User]:',
	// 	user !== null && user.uid,
	// 	user !== null && user.providerData[0]
	// );
	if (isSignedIn) navigate('/home');

	return (
		<div className='app'>
			<Navbar />
			<main className='app_body'>
				<ErrorBoundary
					FallbackComponent={ErrorFallback}
					onReset={() => navigate('/')}>
					<Suspense
						delay={`50ms`}
						fallback={
							<div style={{ height: '100vh', width: '100%' }}>
								<div className='route_container'>
									<Loader size={100} />
								</div>
							</div>
						}>
						<Router className='pages_container' primary={false}>
							<Landing path='/' about={links_about} extras={links_extras} />
							<VendorHome path='home' user={activeUser} />
							<PropertyHome path=':vid/*' user={activeUser} />
							<NotFound default />
						</Router>
					</Suspense>
				</ErrorBoundary>
			</main>
		</div>
	);
};

export default App;

// useEffect(() => {
// 	getAuthRef().onAuthStateChanged((user) => {
// 		if (user) {
// 			batch(() => {
// 				dispatch(gotUserAuthenticated());
// 				dispatch(fetchingUserData());
// 			});

// 			// Dispatch action to get user data matching auth from users collection from db
// 			propertyprovidersCollectionRef
// 				.where('uid', '==', user.uid)
// 				.get()
// 				.then((querySnapshot) => {
// 					if (querySnapshot.empty) {
// 						// Check for a case where an error occurs due to loss of network or bad network.
// 						console.log(
// 							"[App.js > onAuthStateChanged > getUserData] > User doesn't exist on DB, Create a New User"
// 						);
// 						//
// 						// User has signed in for the first time.
// 						let newUser = {
// 							uid: user.uid,
// 							email: user.email,
// 							displayName: user.displayName,
// 							phoneNum: user.phoneNumber,
// 						};
// 						console.group(
// 							'[App.js > onAuthStateChanged]: User Logged in for the first time.'
// 						);
// 						// console.group('User Data is: ');
// 						// console.log('Auth returned: ', user);
// 						console.log('-> UID: ', newUser.uid);
// 						console.log('-> Name: ', newUser.displayName);
// 						console.log('-> Email: ', newUser.email);
// 						console.log('-> Phone Number: ', newUser.phoneNum);
// 						console.groupEnd();

// 						dispatch(
// 							gotUserInitData({ newUser: newUser, is_new_user: true })
// 						);

// 						//Navigate to profile. where fields {email/mobile num} with intial data are disabled to edit by default.
// 						navigate(`/home`);

// 						// create user profile.
// 						// dispatch(createUser(user));
// 					} else {
// 						querySnapshot.forEach((doc) => {
// 							const incomingData = doc.data();
// 							console.log(
// 								'[App.js > onAuthStateChanged > getUserData] > User Exists with id: ',
// 								doc.id,
// 								' & has data: ',
// 								incomingData
// 							);
// 							dispatch(gotUserData({ user: incomingData, docRef: doc.id }));
// 							// // // User is signed in and user exists.
// 							console.group(
// 								'[App.js > onAuthStateChanged]: New Authentication Detected'
// 							);
// 							console.log('-> Authenticated user:', user.uid);
// 							console.log('-> Existing User: ', activeUser.uid);
// 							activeUser.uid !== null &&
// 								(user.uid === activeUser.uid
// 									? console.log('-> User already logged in')
// 									: console.log('-> Logging out the Active User'));
// 							console.groupEnd();
// 						});
// 					}
// 				})
// 				.catch((error) => {
// 					const simple_error = {
// 						error_code: error.code,
// 						error_msg: error.message,
// 					};
// 					console.error(
// 						'[App.js > onAuthStateChanged] > Error checking user ref: ',
// 						simple_error
// 					);
// 					dispatch(errorGettingUserData({ error: simple_error }));
// 				});
// 			// dispatch(getUserData(user));
// 			// if (activeUser.uid !== null) {
// 			// } else if (activeUser.uid === null) {
// 			// }
// 		} else {
// 			// User is signed out.
// 			// dispatch action to set active user as null which in turn should change auth states and only fetch cached posts from fbfs
// 		}
// 	});

// 	// return () => {
// 	// 	cleanup
// 	// };
// }, [authUser, dispatch, activeUser.uid]);
